import React from 'react';
import { Link } from "gatsby";
// import Error404SVG from "../../svg/404SVG.svg";
import "./mensaje.404.component.scss";
import { StaticImage } from 'gatsby-plugin-image';

export const Mensaje404Component = () => {
  return (
    <>
    <div className='error-container'>
      <div className='error-404'>

        <title>No Encontrado</title>
        <br />
        <h1>PÁGINA NO ENCONTRADA</h1>

        <p>
          Lo sentimos{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          la página que buscas no pudo ser localizada.
        </p>
        <br />
          
        <Link to="/">
          <div className='return'>
          Regresar al Inicio
          </div>
        </Link>

        <br />

        {/* <img className="icon-404" src={Error404SVG} alt=""/> */}
        <StaticImage
              className="icon-404 transition-opacity"
              src="../../assets/images/404.png"
              alt=""
              placeholder="tracedSVG"
            />
        <br />

      </div>
      </div>
    </>
  )
}

